
/**
 * @description 动态加载CSS文件
 */
export function loadCss(style: string) {
  const link = document.createElement("style");
  link.type = "text/css";
  link.innerHTML = style;
  link.rel = "stylesheet";
  link.id = "captcha-style";
  document.head.appendChild(link);
}
