import { decryptData } from './sm-Crypto';
export async function fetchRemoteConfig() {
  const remoteConfig = {
    value: null as Window['__APP_CONFIG__'] | null
  };
  const handleEncryptedResponse = (response: Window['__APP_CONFIG__']) => {
    if (!response) return;
    
    const domainInfo = decryptData(response.domainInfo);
    const channelInfo = decryptData(response.channelInfo); 
    const tenantInfo = decryptData(response.tenantInfo);
    const agencyConfig = decryptData(response.agencyConfig);
    const { apiUrl, version, VITE_CAPTCHA_SCENE_ID, VITE_CAPTCHA_PREFIX } = response;

    remoteConfig.value = { domainInfo, channelInfo, tenantInfo, agencyConfig, apiUrl, version, VITE_CAPTCHA_SCENE_ID, VITE_CAPTCHA_PREFIX };
  }
  if (import.meta.env.MODE === 'development') {
    try {
      const response = await fetch('/target/');
      const html = await response.text();

      // 提取配置
      const configMatch = html.match(/__APP_CONFIG__\s*=\s*({[^;]+});/) ;
      if (configMatch) {
        handleEncryptedResponse(JSON.parse(configMatch[1]));
      }
    } catch (err) {
      console.error(err);
    } finally {

    }
    return remoteConfig;
  }
  if (window.__APP_CONFIG__) {
    handleEncryptedResponse(window.__APP_CONFIG__);
  }
  return remoteConfig;
}
